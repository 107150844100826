import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Obfuscate from "react-obfuscate";
import SEO from "./../components/seo"
import Layout from "../components/layout"

class DonneesPersonnelles extends React.Component {
  render() {
    return (
      
      <Layout banner={false} location={this.props.location}>
        <SEO
              title={`Données personnelles`}
              description="Informations concernants les traitements effectués sur les données personelles"/>
        <h1 style={{ marginTop: "6rem" }}>Données personnelles</h1>
        <h2>Responsable du traitement</h2>
        <p>
          Le responsable des données traitées par le site{" "}
          <a href="https://www.france-cadre.fr">https://www.france-cadre.fr</a>{" "}
          est la société « Les professionnels du cadre », dont le siège social
          est situé : Zone artisanale, Route d’Emagny 25115 Pouilley Les Vignes,
        </p>
        <h2>Réglementation</h2>
        <p>
          La société « Les professionnels du cadre » s’engage à ce que la
          collecte et le traitement de vos données, effectués à partir du site{" "}
          <a href="https://www.france-cadre.fr">https://www.france-cadre.fr</a>,
          soient conformes au RGPD (règlement de l’Union européenne no 2016/679
          du 25 mai 2018, dit Règlement Général sur la Protection des Données)
          et à la Loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux
          fichiers et aux libertés (droit français).
        </p>
        <h2>Collecte de données</h2>
        <p>
          Lorsque vous naviguez sur le site{" "}
          <a href="https://www.france-cadre.fr">https://www.france-cadre.fr</a>,
          vous pouvez être amené(e) à faire une demande de contact via le
          formulaire « nous contacter », à créer un compte utilisateur afin de
          passer une commande, suivre le statut de cette commande, télécharger
          vos factures, repasser une commande à partir d’une commande
          précédente.
        </p>
        <p>
          Dans le cadre de ces activités, et sur la base de votre consentement,
          que vous manifestez en remplissant la demande de contact ou le
          formulaire d’inscription, Les professionnels du Cadre traite et
          conserve les données personnelles vous concernant pour répondre à
          votre demande : les informations fournies sur les formulaires à savoir
          votre identité, vos coordonnées de contact et, le cas échéant le
          contenu du message, votre adresse de livraison et de facturation,
          votre n° de téléphone, ou éventuellement votre date de naissance,
          ainsi que toute information communiquée ultérieurement lors de nos
          échanges. Ces données sont traitées par le service concerné par votre
          demande le temps nécessaire pour vous répondre.
        </p>
        <p>
          Les données collectées ne sont jamais utilisées à des fins d’emailing
          (prospection commerciale, newletter, ...).
        </p>
        <p>
          Les données comptables (bon de commande, de livraison, factures) sont
          conservées 10 ans à partir de la clôture de l’exercice.
        </p>
        <p>
          L’historique des conversations par mail est conservé pendant 6 mois.
        </p>
        <p>
          Les informations du compte utilisateur sont conservées pendant 5 ans,
          renouvelé pour toute nouvelle commande.
        </p>
        <h2>Partage de vos données et sécurité de vos données</h2>
        <p>
          Les informations collectées ne sont jamais partagées avec d’autres
          entreprises à des fins de prospection commerciale.
        </p>
        <p>
          Les professionnels du cadre peut donner accès aux informations
          collectées à des prestataires informatiques, dans le cadre de leur
          mission technique de maintenance du site Web. Les prestataires
          informatiques de Les professionnels du cadre assurent via un contrat
          de prestation de services, leur respect du RGPD ainsi qu’une clause de
          confidentialité.
        </p>
        <p>
          Les professionnels du cadre s’engage à protéger vos données
          personnelles contre toute perte, destruction, l’altération, accès ou
          divulgation non autorisée. Pour cela, Les professionnels du cadre met
          en oeuvre des mesures techniques et organisationnelles appropriées, au
          regard de la nature des données et des risques que leur traitement
          comporte, pour préserver la sécurité et la confidentialité de vos
          données personnelles et, notamment, pour empêcher qu’elles soient
          déformées, endommagées, ou que des tiers non autorisés y aient accès.
        </p>
        <p>
          La reproduction, la rediffusion, l’extraction automatique par tout
          moyen de toute information figurant sur le site est interdite.
          L’emploi de robots, programmes et tout moyen automatisé permettant
          l’extraction direct de données est strictement interdit.
        </p>
        <h2>Vos droits</h2>
        <p>
          Vous pouvez consulter, modifier, supprimer les données personnelles
          vous concernant dans la rubrique « mon compte » du site. Il est à
          noter que nous conserverons les données de commandes pour la durée
          légale de 10 ans, et qu’un délai de 30 jours pourrait être appliqué
          pour la gestion d’une demande de modification ou suppression de
          données.
        </p>
        <p>
          Vous pouvez exercer ces droits ou poser toute question relative à la
          gestion de vos données personnelles par Les professionnels du cadre en
          vous adressant à :
        </p>
        <ul>
          <li>Par mail à l’adresse suivante : <Obfuscate email="contact@producadre.fr" /></li>
          <li>
            Par courrier à l’adresse suivante : Les Professionnels du Cadre -
          </li>
          <li>Zone artisanale, Route d’Emagny 25115 Pouilley Les Vignes</li>
        </ul>
        <p>
          Lorsque vous exercez vos droits, vos données personnelles à des fins
          de gestion de votre demande (civilité, nom, prénom, nature de la
          demande, réponse apportée) sont conservées pendant une durée de un an.
        </p>
        <p>
          Vous disposez également du droit de saisir la Commission Nationale de
          l’Informatique et des Libertés (CNIL), 3 Place de Fontenoy – TSA 80715
          – 75334 PARIS CEDEX 07.
        </p>
        {/*<h2>Cookies</h2>
        <p>
          Le site{" "}
          <a href="https://www.france-cadre.fr">https://www.france-cadre.fr</a>{" "}
          collecte des cookies :
        </p>
        <ul>
          <li>
            Google Analytics, afin d’étudier de manière anonyme les statistiques
            de visite, et ainsi optimiser l’expérience des consommateurs.
            (expiration 1 jour, 2 jours et 2 ans)
          </li>
          </ul>*/}
      </Layout>
    )
  }
}

DonneesPersonnelles.propTypes = {
  identifiants: PropTypes.object,
  location: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    identifiants: state.identifiants,
  }
}

export default connect(mapStateToProps)(DonneesPersonnelles)
